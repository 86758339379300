<template>
  <div>
    <van-empty description="暂无数据" v-if="lst.da.length==0" />
    <van-cell-group title="近期将要开的会议">
      <van-list :finished="lst.finish" @load="loadPage" v-model="lst.loading" style="padding: 10px 0px;">
        <div class="wcard" v-for="item in lst.da" :key="item.ID">
          <div class="bd">
            <van-row :gutter="8">
              <van-col :span="23" :offset="1">
                <p>{{ item.TITLE }} [{{item.CAT}}]</p>
                <p style="font-size: 14px; color: #999">{{item.DESCRIPTION}}</p>
                <p style="font-size: 14px; color: #999"><b>主持人：</b>{{item.HOST}}</p>
                <p style="font-size: 14px; color: #999">
                  <b>时间：</b>{{item.TIME|datetime('YYYY-MM-DD HH:mm')}}
                  <b>地点：</b>{{item.LOC}}
                </p>
              </van-col>
            </van-row>
          </div>
          <div class="ft">
            <van-row style="text-align: center">
              <van-col :span="6"><van-button plain type="primary"
                  :to="'/meet/edit/info?guid='+item.GUID">修改</van-button> </van-col>
              <van-col :span="6"><van-button plain type="warning"
                  :to="'/meet/edit/sign?guid='+item.GUID">点名</van-button> </van-col>
              <van-col :span="6"><van-button plain type="info" :to="'/meet/edit/pic?guid='+item.GUID">图片</van-button>
              </van-col>
              <van-col :span="6"><van-button plain type="danger" @click="del(item.GUID)">删除</van-button> </van-col>
            </van-row>
          </div>
        </div>
      </van-list>
    </van-cell-group>
  </div>
</template>

<script>
  export default {
    name: "index",
    data() {
      return {
        sea: {
          PAGE_SIZE: 10,
          PAGE_INDEX: -1,
          KEY: "",
          DUR: 'RECENT'
        },
        lst: {
          da: [],
          loading: false,
          finish: false
        },
      }
    },
    created() { },
    methods: {
      filter() {
        this.lst.da.length = 0;
        this.sea.PAGE_INDEX = 0;
        this.getList();
      },
      loadPage() {
        this.sea.PAGE_INDEX += 1;
        this.getList();
      },
      getList() {
        let self = this;
        self.whale.remote.getCollection({
          url: '/api/Mobile/MEET/MinfoApi/GetList',
          data: self.sea,
          completed(its, n) {
            self.lst.da.push(...its.ITEMS);
            self.lst.loading = false;
            if (self.lst.da.length >= n) self.lst.finish = true;
          }
        })
      },
      del(g) {
        let self = this;
        this.$dialog.confirm({
          message: "真的要删除这个会议吗？"
        }).then(() => {
          self.whale.remote.getResult({
            url: '/api/Mobile/MEET/MinfoApi/Delete',
            data: { GUID: g },
            completed() {
              self.filter();
            }
          })
        }).catch(() => { })
      },
    }
  }
</script>

<style scoped>
  .wcard {
    margin: 0 12px 12px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, .1)
  }

  .wcard .ft {
    border-top: 1px dashed #ebedf0;
    padding: 8px 16px;
    font-size: 12px;
    min-height: 20px
  }

  .wcard .ft .ov {
    color: #ee0a24;
    font-weight: bold
  }

  .wcard .bd {
    padding: 0px 15px
  }

  .wcard .bd .lf {
    text-align: center;
    color: #ee0a24;
    padding-top: 10px
  }

  .wcard .bd .lf h2 {
    font-size: 30px;
    font-weight: 500;
    display: inline;
  }

  .wcard .bd .lf span {
    font-size: 40%;
    font-weight: normal;
    margin-left: 2px
  }

  .wcard .bd .lf p {
    font-size: 14px;
    line-height: 16px
  }

  .van-button {
    height: 30px;
    border-radius: 10px
  }
</style>